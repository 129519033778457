import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getFirestore} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyA2FwOGh1VmFEywtKIB6_hz4rMuOCJgkrk",
    authDomain: "pmr446hu-2ac02.firebaseapp.com",
    databaseURL: "https://pmr446hu-2ac02-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "pmr446hu-2ac02",
    storageBucket: "pmr446hu-2ac02.appspot.com",
    messagingSenderId: "875885238487",
    appId: "1:875885238487:web:04146b6eaec03868fa3892",
    measurementId: "G-GGV8XKLX29"
};

const conf = initializeApp(firebaseConfig);

const auth = getAuth(conf);
auth.languageCode = 'hu';

const gProvider = new GoogleAuthProvider();
gProvider.setCustomParameters({prompt: "select_account"});

const fProvider = new FacebookAuthProvider();
fProvider.addScope('public_profile');
fProvider.setCustomParameters({'display':'popup'});

const db = getFirestore();

export const analytics = getAnalytics(conf);
export { auth, fProvider, db };
export const signInWithGooglePopup = () => signInWithPopup(auth, gProvider);
export default conf;