export const validatePasswordForm = (payload) => {
    const errors = {};
    let message = "";
    let isFormValid = true;

    if (!payload || typeof payload.password !== "string" || payload.password.trim().length < 8) {
        isFormValid = false;
        if(payload.password.trim().length === 0){
            errors.password = "A jelszó mező kitöltése kötelező!";
        }else{
            errors.password = "A jelszónak legalább 8 karakternek kell lennie!";
        };
    }

    if (!payload || typeof payload.password_rep !== "string" || payload.password_rep.trim().length < 8) {
        isFormValid = false;
        if(payload.password_rep.trim().length === 0){
            errors.password_rep = "A jelszó megerősítése mező kitöltése kötelező!";
        }else{
            errors.password_rep = "A jelszónak megerősítésének legalább 8 karakternek kell lennie!";
        };
    }

    if(isFormValid && (payload.password !== payload.password_rep) ) {
        isFormValid = false;
        errors.password = "A jelszavak nem egyeznek meg!";
    }

    if (!isFormValid) {
        message = "Hibásan kitöltött űrlap!";
    }

    return {
        success: isFormValid,
        message,
        errors
    };
}

export const validateLostForm = (payload) => {
    const errors = {};
    let message = "";
    let isFormValid = true;

    if (!payload || typeof payload.email !== "string" || payload.email.trim().length === 0 ) {
        isFormValid = false;
        errors.email = "Az E-mail mező kitöltése kötelező!";
    }
    
    if (!isFormValid) {
        message = "Hibásan kitöltött űrlap!";
    }

    return {
        success: isFormValid,
        message,
        errors
    };
}
export const validateRegisterForm = (payload) => {
    const errors = {};
    let message = "";
    let isFormValid = true;

    if (!payload || typeof payload.email !== "string" || payload.email.trim().length === 0 ) {
        isFormValid = false;
        errors.email = "Az E-mail mező kitöltése kötelező!";
    }

    if (!payload || typeof payload.password !== "string" || payload.password.trim().length < 8) {
        isFormValid = false;
        if(payload.password.trim().length === 0){
            errors.password = "A jelszó mező kitöltése kötelező!";
        }else{
            errors.password = "A jelszónak legalább 8 karakternek kell lennie!";
        };
    }

    if (!payload || typeof payload.password_rep !== "string" || payload.password_rep.trim().length < 8) {
        isFormValid = false;
        if(payload.password_rep.trim().length === 0){
            errors.password_rep = "A jelszó megerősítése mező kitöltése kötelező!";
        }else{
            errors.password_rep = "A jelszónak megerősítésének legalább 8 karakternek kell lennie!";
        };
    }

    if(isFormValid && (payload.password !== payload.password_rep) ) {
        isFormValid = false;
        errors.password = "A jelszavak nem egyeznek meg!";
    }

    if (!isFormValid) {
        message = "Hibásan kitöltött űrlap!";
    }

    return {
        success: isFormValid,
        message,
        errors
    };
}

export const validateLoginForm = (payload) => {
    const errors = {};
    let message = "";
    let isFormValid = true;

    if (!payload || typeof payload.email !== "string" || payload.email.trim().length === 0 ) {
        isFormValid = false;
        errors.email = "Az E-mail mező kitöltése kötelező!";
    }

    if (!payload || typeof payload.password !== "string" || payload.password.trim().length < 8) {
        isFormValid = false;
        if(payload.password.trim().length === 0){
            errors.password = "A jelszó mező kitöltése kötelező!";
        }else{
            errors.password = "A jelszónak legalább 8 karakternek kell lennie!";
        };
    }

    if (!isFormValid) {
        message = "Hibásan kitöltött űrlap!";
    }

    return {
        success: isFormValid,
        message,
        errors
    };
};