import React, {useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { validatePasswordForm } from '../Utils/validate';
import { auth, signInWithGooglePopup, fProvider } from '../Utils/firebaseConfig';
import { confirmPasswordReset, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { trackPromise } from 'react-promise-tracker';
import NewPasswordForm from '../Components/NewPasswordForm';

const NewPasswordContainer = () => {
    const navigate = useNavigate();
    const [state,setState] = useState({errors: {}, user: { password: '', password_rep: ''}});
    
    const handleChange = (event) => {
        const field = event.target.name;
        let user = state.user;
        let errors = state.errors;
        user[field] = event.target.value;
        errors[field] = "";
        errors['message'] = "";
        setState({ ...state, user });
        setState({ ...state, errors })
    };

    const validateForm = (event) => {
        event.preventDefault();
        var payload = validatePasswordForm(state.user);
        if (payload.success) {
            setState({ ...state, errors: {} });
            var user = {
                usr: state.user.email,
                pw: state.user.password
            };
            submitLogin(user);
        } else {
            const errors = payload.errors;
            setState({ ...state, errors });
        }
    }


    const query = new URLSearchParams(useLocation().search);
    const mode = query.get('mode');
    const oobCode = query.get('oobCode');
    const lang = query.get('lang');
    useEffect(() => {
        if(mode !== 'resetPassword' || !oobCode)
        {
            let errors = state.errors;
            errors['message'] = 'Érvénytelen jelszó-visszaállítási kérelem!';
            setState({...state, errors});
        };
    },[mode, oobCode])

    const submitLogin = async (user) => {
        await trackPromise(
            (async () => {
                let errors = state.errors;
                let user = state.user;
                await confirmPasswordReset(auth, oobCode, state.password)
                .then(() => {
                    errors['message'] = "A jelszavad visszaállítva! Most már be tudsz lépni!";
                    errors['css'] = "success";
                    user['password'] = '';
                    user['password_rep'] = '';
                    setState({...state, errors});
                    setState({...state, user});
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            navigate('/login');
                            resolve();
                        },3000);
                    })
                })
                .catch((err) => {
                    console.log(err);
                    if(err.code === 'auth/invalid-action-code'){
                        errors['message'] = 'Hibás aktiváló kód!';
                    }else{
                        errors['message'] = "Valami hiba történt!";
                    };
                    errors['css'] = "success";
                    setState({...state, errors});
                    setState({...state, user});
                });
            })()
        )
    }
    
    return (
        <NewPasswordForm 
            onSubmit = {validateForm}
            onChange = {handleChange}
            errors   = {state.errors}
            user     = {state.user}
        />
    );
};

export default NewPasswordContainer;