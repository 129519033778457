import React, { useState } from 'react';
import RegisterForm from '../Components/RegisterForm';
import { validateRegisterForm } from '../Utils/validate';
/*import { auth } from '../Utils/firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';*/
import client from '../Utils/apiClient';
import { trackPromise } from 'react-promise-tracker';
import MyStorage from '../Utils/myStorage';

const RegisterContainer = () => {
    const [state, setState] = useState({ errors: {}, user: { email: '', password: '', password_rep: '' } });
    const handleChange = (event) => {
        const field = event.target.name;
        let user = state.user;
        let errors = state.errors;
        user[field] = event.target.value;
        errors[field] = "";
        errors['message'] = "";
        setState({ ...state, user });
        setState({ ...state, errors })
    };
    const validateForm = (event) => {
        event.preventDefault();
        var payload = validateRegisterForm(state.user);
        if (payload.success) {
            setState({ ...state, errors: {} });
            submitRegister();
        } else {
            const errors = payload.errors;
            errors['css'] = 'danger';
            setState({ ...state, errors });
        }
    }
    const submitRegister = async () => {
        try{
            //let userCredential;
            await trackPromise(
                (async () => {
                    client.post('register',{email: state.user.email, password: state.user.password}, (data) => {
                        
                        setState({
                            ...state,
                            user: {
                                ...state.user,
                                email: '',
                                password: '',
                                password_rep: ''
                            },
                            errors: {
                                ...state.errors,
                                message: data.message,
                                css: (data.success) ? "success" : "danger"
                            }
                        });                  
                    
                    
                    }, MyStorage.local.get('token'));
                })()
            );
            
        }catch(err)
        {
            console.error(err);
        };
    };
    
    return (
        <RegisterForm
            onSubmit={validateForm}
            onChange={handleChange}
            errors={state.errors}
            user={state.user}
        />
    );
};

export default RegisterContainer;