import React from 'react';
import FaIcon from '../Widgets/FaIcon';
import Field from './Field';
import Button from './Button';

const NewPasswordForm = ({onSubmit,onChange,errors,user}) => {
    return (
        <div className="container-fluid">
            <div className="loginWrapper">
                <center><img src={`${localStorage.getItem('domain')}/images/446logo.png`} alt="446Logo" className="mb-3" /></center>
                <div className='loginBox'>
                    <h3 className='loginBoxTitle mb-3'>Új jelszó megadása</h3>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 mb-3">
                            <form id="loginForm" onSubmit={onSubmit}>
                                {errors?.message && <div className="alert alert-danger mb-3" dangerouslySetInnerHTML={{__html: errors.message}}></div>}
                                <div className="form-group mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text"><FaIcon type="solid" icon="key" /></span>
                                        <Field type="password" name="password" id="password" onChange={onChange} placeholder="Jelszó" value={user?.password}/>
                                    </div>
                                    {errors?.password && <span style={{color:'red'}}>{errors.password}</span>}
                                </div>
                                <div className="form-group mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text"><FaIcon type="solid" icon="key" /></span>
                                        <Field type="password" name="password_rep" id="password_rep" onChange={onChange} placeholder="Jelszó megerősítése" value={user?.password_rep}/>
                                    </div>
                                    {errors?.password_rep && <span style={{color:'red'}}>{errors.password_rep}</span>}
                                </div>
                                <div className="btn-group mb-3 w-100">
                                    <Button type="submit" variant="success w-100" icon={{ type: "solid", icon: "lock-open" }} text="Jelszó mentése" />
                                </div>
                                <div className="btn-group w-100">
                                    <Button href="/login" variant="warning w-100" icon={{ type: "solid", icon: "sign-in-alt" }} text="Bejelentkezés" />
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default NewPasswordForm;