import React, {useEffect, useState} from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { applyActionCode, getAuth } from 'firebase/auth';
import client from '../Utils/apiClient';
import MyStorage from '../Utils/myStorage';
import Message from '../Components/Message';
import { Button } from 'react-bootstrap';

const ActivateContainer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        variant: '',
        text: ''
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const mode = queryParams.get('mode');
        const oobCode = queryParams.get('oobCode');
        

        if (mode === 'verifyEmail' && oobCode) {            
            client.post('activate',{mode:'verifyEmail',oobCode:oobCode},(data) => { setState({ variant: (data.success) ? "success" : "danger", text: data.message }); },MyStorage.local.get('token'));
        }else if(mode === "resetPassword" && oobCode) {
            client.post('activate',{mode:'resetPassword',oobCode:oobCode},(data) => { setState({ variant: (data.success) ? "success" : "danger", text: data.message }); },MyStorage.local.get('token'));
        };

    }, [location]);



    return (
        <>
            <Message variant={state.variant} text={state.text} />
            
        </>
    );
};

export default ActivateContainer;