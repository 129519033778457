import React from 'react';
import FaIcon from '../Widgets/FaIcon';
import Field from './Field';
import Button from './Button';

const LostPWForm = ({onSubmit,onChange,errors,user}) => {
    return (
        <div className="container-fluid">
            <div className="loginWrapper">
                <center><img src={`${localStorage.getItem('domain')}/images/446logo.png`} alt="446Logo" className="mb-3" /></center>
                <div className='loginBox'>
                    <h3 className='loginBoxTitle mb-3'>Jelszóemlékeztető</h3>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 mb-3">
                            <form id="loginForm" onSubmit={onSubmit}>
                                {errors?.message && <div className="alert alert-danger mb-3" dangerouslySetInnerHTML={{__html: errors.message}}></div>}
                                <div className="form-group mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text"><FaIcon type="solid" icon="at" /></span>
                                        <Field type="email" name="email" id="email" onChange={onChange} placeholder="E-mail cím" value={user?.email}/>
                                    </div>
                                    {errors?.email && <span style={{color:'red'}}>{errors.email}</span>}
                                </div>
                                <div className="btn-group mb-3 w-100">
                                    <Button type="submit" variant="danger w-100" icon={{ type: "solid", icon: "question" }} text="Új jelszó kérése" />
                                </div>
                                <div className="btn-group w-100">
                                    <Button href="/login" variant="success w-100" icon={{ type: "solid", icon: "sign-in-alt" }} text="Bejelentkezés" />
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default LostPWForm;