import React from 'react';
import { Alert } from 'react-bootstrap';
import Button from './Button';

const Message = ({ variant, text }) => {
    return (
        <div className="container-fluid">
            <div className="loginWrapper">
                <center><img src={`${localStorage.getItem('domain')}/images/446logo.png`} alt="446Logo" className="mb-3" /></center>
                <div className='loginBox'>
                    <h3 className='loginBoxTitle mb-3'>Üzenet</h3>
                    <Alert variant={variant}>
                        {text}
                    </Alert>
                    <Button href="/login" variant="success" text="Belépés" icon={{ type: "solid", icon: "sign-in-alt" }} />
                </div>
            </div>
        </div>
    );
};

export default Message;