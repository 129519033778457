import React from 'react';
import FaIcon from '../Widgets/FaIcon';
import { Link } from 'react-router-dom';

const Button = ({type, variant, text, icon, onClick, href}) => {
    return (
        <>
        {href && 
            <Link 
                to={href} 
                className={`btn btn-${variant}`}
            >
                {icon && 
                    <FaIcon type={icon.type} icon={icon.icon} />
                }&nbsp;&nbsp;
                {text}                
            </Link>
        }
        {!href &&
            <button 
                type={type}
                className={`btn btn-${variant}`}
                onClick={onClick}
            >
                {icon && 
                    <FaIcon type={icon.type} icon={icon.icon} />
                }&nbsp;&nbsp;
                {text}
            </button>
        }
        </>
    );
};

export default Button;