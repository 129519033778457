import { useEffect, useLayoutEffect, useState } from "react";
import {Routes, Route } from "react-router-dom";
import "./App.css";
import LoginContainer from "./Containers/LoginContainer";
import RegisterContainer from "./Containers/RegisterContainer";
import conf from './Utils/firebaseConfig';
import { getDatabase, ref, onValue} from "firebase/database";
import ActivateContainer from "./Containers/ActivateContainer";
import LostPWContainer from "./Containers/LostPWContainer";
import NewPasswordContainer from "./Containers/NewPWContainer";
import UnderConstruction from "./Components/UnderConstruction";
import client from "./Utils/apiClient";
import MyStorage from "./Utils/myStorage";

function App() {
  const construction = false;

  const [ init, setInit] = useState(false);
  
  useEffect(() => {
    /*const database = getDatabase(conf);
    const collectionRef = ref(database, "users");
    const fetchData = () => {
      onValue(collectionRef, (snapshot) => {
        const dataItem = snapshot.val();
        console.log(dataItem);
      })
    };

    fetchData();*/
  }, []);

  useLayoutEffect(() => {
    if(!init){
      client.post('auth',null,(data) => {
        if(data.success) MyStorage.local.put('token', data.token);
      });

      const domain = window.location.hostname;
      let staticDomain;
      let domainExt;
      let cssFiles = {
        bootstrap: 'bootstrap.min.css',
        fapro: 'fa.all.min.pro.css',
        pub: 'pub.css'
      }
      if (domain === 'localhost' || domain === 'auth.446.local') {
        staticDomain = 'http://static.446.local/';
        domainExt = 'local';
      } else if (domain === 'auth.446.hu') {
        staticDomain = 'https://static.446.hu/';
        domainExt = 'hu';
      }

      Object.entries(cssFiles).map(([key, value]) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `https://static.446.hu/css/${value}`;
        document.head.appendChild(link);
      });

      const icon = document.createElement('link');
      icon.rel = 'icon';
      icon.href = `${staticDomain}images/favicon.png`;
      icon.type = 'image/png';
      document.head.appendChild(icon);

      localStorage.setItem('domain', staticDomain);
      localStorage.setItem('domainExt', domainExt);
      
      setInit(true);
    };
  }, []);
  
  
  return (
    <>
      <Routes>
        {construction &&
          <>
            <Route path='/' element={<UnderConstruction />} />
            <Route path='/login' element={<UnderConstruction />} />
            <Route path='/register' element={<UnderConstruction />} />
          </>
        }
        {!construction &&
          <>
            <Route path='/' element={<LoginContainer />} />
            <Route path='/login' element={<LoginContainer />} />
            <Route path='/register' element={<RegisterContainer />} />
            <Route path='/activate' element={<ActivateContainer />} />
            <Route path='/lostpw' element={<LostPWContainer />} />
            <Route path='/newpassword' element={<NewPasswordContainer />} />
          </>        
        }
      </Routes>
    </>
  );
}

export default App;
