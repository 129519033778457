import React, { useState } from 'react';
import LostPWForm from '../Components/LostPWForm';
import { validateLostForm } from '../Utils/validate';
import { trackPromise } from 'react-promise-tracker';
import client from '../Utils/apiClient';
import MyStorage from '../Utils/myStorage';

const LostPWContainer = () => {
    const [state,setState] = useState({errors: {}, user: { email: ''}});
    
    const handleChange = (event) => {
        const field = event.target.name;
        let user = state.user;
        let errors = state.errors;
        user[field] = event.target.value;
        errors[field] = "";
        errors['message'] = "";
        setState({ ...state, user });
        setState({ ...state, errors })
    };

    const validateForm = (event) => {
        event.preventDefault();
        var payload = validateLostForm(state.user);
        if (payload.success) {
            setState({ ...state, errors: {} });
            submitForm();
        } else {
            const errors = payload.errors;
            setState({ ...state, errors });
        }
    }
    const submitForm = async () => {
        await trackPromise(
            (async () => {
                client.post('lostpw',{email: state.user.email},(data) => {
                    console.log(data);

                    setState({
                        ...state,
                        errors: {
                            ...state.errors,
                            message: data.message,
                            css: (data.success) ? "success" : "danger"
                        },
                        user: {
                            ...state.user,
                            email: ''
                        }
                    });
                },MyStorage.local.get('token'));
            })()
        );
    }



    return (
        <LostPWForm 
            onChange={handleChange}
            onSubmit={validateForm}
            errors={state.errors}
            user={state.user}
        />
    );
};

export default LostPWContainer;