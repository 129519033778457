import React from 'react';

const Field = ({type, name, id, value, onChange, css, placeholder = "",}) => {
    return (
        <input 
            type={type}
            name={name}
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            className={`form-control ${css}`} 
        />
    );
};

export default Field;