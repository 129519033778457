import React from 'react';
import FaIcon from '../Widgets/FaIcon';
import Field from './Field';
import Button from './Button';

const LoginForm = ({onSubmit,onChange,errors,user,googleButtonHandler,facebookButtonHandler}) => {
    return (
        <div className="container-fluid">
            <div className="loginWrapper">
                <center><img src={`${localStorage.getItem('domain')}/images/446logo.png`} alt="446Logo" className="mb-3" /></center>
                <div className='loginBox'>
                    <h3 className='loginBoxTitle mb-3'>Bejelentkezés</h3>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mb-3">
                            <form id="loginForm" onSubmit={onSubmit}>
                                {errors?.message && <div className="alert alert-danger mb-3" dangerouslySetInnerHTML={{__html: errors.message}}></div>}
                                <div className="form-group mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text"><FaIcon type="solid" icon="at" /></span>
                                        <Field type="email" name="email" id="email" onChange={onChange} placeholder="E-mail cím vagy hívójel" value={user.email}/>
                                    </div>
                                    {errors?.email && <span style={{color:'red'}}>{errors.email}</span>}
                                </div>
                                <div className="form-group mb-3">
                                    <div className="input-group">
                                        <span className="input-group-text"><FaIcon type="solid" icon="key" /></span>
                                        <Field type="password" name="password" id="password" onChange={onChange} placeholder="Jelszó"  value={user.password}/>
                                    </div>
                                    {errors?.password && <span style={{color:'red'}}>{errors.password}</span>}
                                </div>
                                <div className="btn-group mb-3 w-100">
                                    <Button type="submit" variant="success w-100" icon={{ type: "solid", icon: "sign-in-alt" }} text="Belépés" />
                                </div>
                                <div className="btn-group w-100">
                                    <Button href="/lostpw" variant="danger w-100" icon={{ type: "solid", icon: "lock" }} text="Elfelejtetted a jelszavad?" />
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div id="loginSocial">
                                <div className="input-group mb-3 w-100">
                                    <Button onClick={googleButtonHandler} type="button" variant="google disabled w-100" icon={{ type: "brands", icon: "google" }} text="Google fiókkal" />
                                </div>
                                <div className="input-group mb-3 w-100">
                                    <Button type="button" variant="facebook disabled w-100" icon={{ type: "brands", icon: "facebook-f" }} text="Facebook fiókkal" />
                                </div>
                                <div className="input-group mb-3 w-100">
                                    <Button href="/register" type="button" variant="warning w-100" icon={{ type: "solid", icon: "user-plus" }} text="Nincs még fiókod? Regisztrálj itt!" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default LoginForm;