import React, { useState } from 'react';
import LoginForm from '../Components/LoginForm';
import { validateLoginForm } from '../Utils/validate';
import { auth, signInWithGooglePopup, fProvider, db } from '../Utils/firebaseConfig';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { trackPromise } from 'react-promise-tracker';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import client from '../Utils/apiClient';
import MyStorage from '../Utils/myStorage';

const LoginContainer = () => {
    const [state,setState] = useState({errors: {}, user: { email: '', password: ''}});
    const handleChange = (event) => {
        const field = event.target.name;
        let user = state.user;
        let errors = state.errors;
        user[field] = event.target.value;
        errors[field] = "";
        errors['message'] = "";
        setState({ ...state, user });
        setState({ ...state, errors })
    };
    const validateForm = (event) => {
        event.preventDefault();
        var payload = validateLoginForm(state.user);
        if (payload.success) {
            setState({ ...state, errors: {} });
            var user = {
                usr: state.user.email,
                pw: state.user.password
            };
            submitLogin(user);
        } else {
            const errors = payload.errors;
            setState({ ...state, errors });
        }
    }

    const userNotVerified = () => {
        auth.signOut();
        let errors = {};
        errors["message"] = "Az e-mail címed nincs megerősítve!";
        errors["css"] = "danger";
        setState({...state, errors});
    }
    const createOrUpdateUserDoc = async (user) => {
        const userRef = doc(db, `users/${user.uid}`);
        const userDoc = await getDoc(userRef);
        if(!userDoc.exists()){
            await setDoc(userRef, {
                displayName: user.displayName,
                email: user.email,
                gps: JSON.stringify({lat:0, lon: 0})
            });
        }else{
            console.log(userDoc.data().email);
        }
    }
    const redirectToDomain = () => {
        const domain = "http://app.446.hu";
        window.location.href = `${domain}`;
    }
    const setCookies = async (user) => {
        const idToken = MyStorage.local.get('token');        
        Cookies.set('auth', CryptoJS.AES.encrypt(JSON.stringify(user), '446.hu#aA123456&#&').toString(), {
            path: '/',
            domain: '.446.hu',
            secure: false,
            sameSite: 'strict'
        });
        Cookies.set('token', idToken, {
            path: '/',
            domain: '.446.hu',
            secure: false,
            sameSite: 'strict'
        });
    }

    const submitLogin = async (usr) => {
        await trackPromise(
            (async () => {
                client.post('login',{email: usr.usr, password: usr.pw}, (data) => {
                    if(!data.success){
                        let errors = {};
                        errors["css"] = (data.success) ? "success" : "danger";
                        errors["message"] = data.message;
                        setState({...state, errors});
                    }else{
                        setState({...state,user: {...state.user,email: '',password: ''},errors: {...state.error,message: 'Sikeres belépés! Hamarosan átirányítunk!',css: 'success'}});
                        return new Promise((resolve) => {
                            setTimeout(async () => {
                                await setCookies(data);                                
                                redirectToDomain();
                                resolve();
                            },3000);
                        })
                    }
                },MyStorage.local.get('token'));
            })()
        )
    }
    const submitWithGoogle = async () => {
        const response = await signInWithGooglePopup();
        const user = response.user;
        if(user.emailVerified === false)
            {
                userNotVerified();
            }else{
                setState({...state,user: {...state.user,email: '',password: ''},errors: {...state.error,message: 'Sikeres belépés! Hamarosan átirányítunk!',css: 'success'}});
                return new Promise((resolve) => {
                    setTimeout(async () => {
                        await setCookies(user);                                
                        await createOrUpdateUserDoc(user);                        
                        redirectToDomain();
                        resolve();
                    },3000);
                })
            }        
    }
    const submitWithFacebook = async () => {
        await signInWithPopup(auth, fProvider).then((result) => { console.log(result); })
    }

    return (
        <LoginForm 
            onSubmit = {validateForm}
            onChange = {handleChange}
            errors   = {state.errors}
            user     = {state.user}
            googleButtonHandler = {submitWithGoogle}
            facebookButtonHandler = {submitWithFacebook}
        />
    );
};

export default LoginContainer;